import React from 'react';
import Layout from '../components/layout';
import "../styles/mystyles.scss"
import RevNav from '../components/referrals/refSideNav';


const ProcessPage = () => (



  <Layout>
    <div className="grid-container">
    <div class="notification">

    <div class="columns">

    <div class="column is-3" >

    <RevNav></RevNav>



        </div>

            <section className="usa-graphic-list usa-section">
                    <div className="grid-container">
                    <div class="border-bottom-1 border-accent-cool-darker  padding-bottom-2 margin-bottom-2 usa-prose">
                        <h1 className="text-bold margin-0"></h1>
                        </div>
                    </div>
            
                
            </section>
            
        </div>


        </div>
        </div>
  </Layout>
);



export default ProcessPage;
